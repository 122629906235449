import { render, staticRenderFns } from "./SearchCard.vue?vue&type=template&id=3845865e"
import script from "./SearchCard.vue?vue&type=script&lang=js"
export * from "./SearchCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3845865e')) {
      api.createRecord('3845865e', component.options)
    } else {
      api.reload('3845865e', component.options)
    }
    module.hot.accept("./SearchCard.vue?vue&type=template&id=3845865e", function () {
      api.rerender('3845865e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/wordpress/SearchCard.vue"
export default component.exports